import React from 'react';
import './input.scss';

interface InputProps {
    [x: string]: any;
}

export default function Input(props: InputProps) {
    return <input {...props} />;
}
