import React from 'react';

export const Tv = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 13.0002C18.898 13.0002 18 12.1042 18 11.0002C18 9.89725 18.898 9.00025 20 9.00025C21.103 9.00025 22 9.89725 22 11.0002C22 12.1042 21.103 13.0002 20 13.0002V13.0002ZM20 18.0002C18.898 18.0002 18 17.1042 18 16.0002C18 14.8972 18.898 14.0002 20 14.0002C21.103 14.0002 22 14.8972 22 16.0002C22 17.1042 21.103 18.0002 20 18.0002V18.0002ZM17 18.5002C17 21.0002 12.484 21.0002 11 21.0002H8C6.516 21.0002 2 21.0002 2 18.5002V10.5002C2 8.00025 6.516 8.00025 8 8.00025H11C12.484 8.00025 17 8.00025 17 10.5002V18.5002ZM21 6.00025H16.527C16.527 5.42725 15.367 4.25425 14.825 3.88225L17.854 0.85425C18.049 0.65825 18.049 0.34225 17.854 0.14625C17.658 -0.04875 17.342 -0.04875 17.146 0.14625L13.91 3.38325C12.669 2.87325 11.332 2.87225 10.089 3.38325L6.854 0.14625C6.658 -0.04875 6.342 -0.04875 6.146 0.14625C5.951 0.34225 5.951 0.65825 6.146 0.85425L9.174 3.88225C8.634 4.25325 7.472 5.42625 7.472 6.00025H3C1.346 6.00025 0 7.34625 0 9.00025V20.0002C0 21.6542 1.346 23.0002 3 23.0002H21C22.654 23.0002 24 21.6542 24 20.0002V9.00025C24 7.34625 22.654 6.00025 21 6.00025V6.00025Z"
                fill="#343951"
            />
        </svg>
    );
};

export const Connection = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.9409 9.2787C21.5039 9.6037 21.9259 10.1387 22.1019 10.7807C23.1659 10.5577 23.4669 10.4027 23.8419 10.2107C23.5599 8.3297 22.8459 6.5907 21.7949 5.0997C21.6699 6.3017 21.3919 7.7497 20.9409 9.2787"
                fill="#343951"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.0415 4.03072C4.4435 5.62572 5.4565 7.70172 5.8885 9.95472C7.3915 10.0677 8.5595 11.5167 8.0875 13.1367C9.2885 13.9397 10.5035 15.0547 11.1155 15.6457C12.3165 15.0537 13.6625 15.7767 13.9385 16.9957C14.0965 16.9877 14.2615 16.9787 14.4185 16.9687C14.4595 15.6007 15.8475 14.6487 17.1545 15.1757C17.5305 14.5777 17.8745 13.9537 18.1655 13.3947C17.6395 12.9817 17.2875 12.3667 17.2235 11.6647C14.5995 11.0447 12.2755 9.56272 10.5965 7.44372C8.9615 8.09272 7.2475 6.90472 7.1875 5.21272L7.1665 5.20772C5.8145 4.83072 4.5575 4.22172 3.5285 3.49872C3.3575 3.66872 3.2015 3.85072 3.0415 4.03072"
                fill="#343951"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.6782 19.3061C11.5342 19.7281 10.3822 19.0551 10.0762 17.9801C8.75121 17.9131 7.43621 17.7721 6.08921 17.5571C5.85821 18.1281 5.29121 18.5401 4.58721 18.4861C4.26421 19.0651 3.80521 19.8081 3.36121 20.3151C4.65521 21.6591 6.26021 22.6971 8.05921 23.3231C10.0672 22.2371 11.5952 21.1501 13.0302 19.8751C12.9182 19.6901 12.7992 19.4991 12.6782 19.3061"
                fill="#343951"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.4927 15.3998C23.9427 13.8788 24.0347 12.6068 23.9537 11.2678C23.4647 11.4958 22.6897 11.6848 22.1647 11.7868C21.9627 13.1998 20.5827 14.2488 19.0597 13.8418C18.7467 14.4438 18.3727 15.1238 17.9577 15.7748C18.1387 15.9978 18.2737 16.2558 18.3467 16.5438C19.9697 16.2928 21.6267 15.9458 23.4927 15.3998"
                fill="#343951"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.35498 4.21622C7.55498 3.70422 7.91798 3.27922 8.38098 2.99622C8.13798 2.04722 8.08898 1.57322 7.99798 0.695221C6.63298 1.17822 5.37798 1.89322 4.28998 2.80722C5.20298 3.41522 6.23698 3.89522 7.35498 4.21622"
                fill="#343951"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.87843 14.9174C5.81243 15.2704 5.74243 15.5844 5.66943 15.8624C5.88143 16.0444 6.03943 16.2804 6.12443 16.5524C7.45243 16.7694 8.74743 16.9074 10.0494 16.9764C10.1074 16.7294 10.2074 16.5014 10.3474 16.3004C9.54943 15.5344 8.53643 14.6594 7.60943 14.0284C7.18743 14.5344 6.57443 14.8664 5.87843 14.9174"
                fill="#343951"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.437 6.8969C12.948 8.7689 15.009 10.0849 17.334 10.6629C17.681 9.6059 18.716 8.81091 19.991 8.96491C20.445 7.41791 20.83 5.57191 20.856 3.93891C20.251 3.2739 19.58 2.67391 18.84 2.15891C17.445 3.66891 14.899 5.10191 12.139 5.51391C12.054 6.04991 11.806 6.5309 11.437 6.8969"
                fill="#343951"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.32861 2.65861C10.6696 2.46561 11.7956 3.34061 12.0936 4.50961C14.4646 4.13261 16.7336 2.90061 17.9796 1.61461C15.3596 0.0956067 12.2046 -0.444393 8.97461 0.395607C9.06061 1.29561 9.10961 1.77461 9.32861 2.65861"
                fill="#343951"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.5376 20.7602C12.2676 21.8682 10.8976 22.8632 9.41158 23.6982C9.40958 23.6992 9.40058 23.7052 9.39758 23.7072C11.2786 24.1222 13.0276 24.0822 14.8216 23.6462C14.4656 22.6462 14.2816 22.1092 13.5376 20.7602"
                fill="#343951"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.3315 17.56C18.0085 18.725 16.7065 19.396 15.5325 18.816C15.1365 19.251 14.7315 19.677 14.3065 20.081C15.2075 21.682 15.4315 22.372 15.7855 23.372C19.0955 22.265 21.7665 19.759 23.0875 16.552C21.4685 16.991 20.0985 17.295 18.3315 17.56"
                fill="#343951"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.62161 11.0483C3.92361 10.5983 4.37161 10.2603 4.89261 10.0803C4.48961 8.02732 3.56661 6.17832 2.39861 4.81732C1.22661 6.37832 0.430606 8.23032 0.133606 10.2463C1.13361 10.4103 1.94061 10.5003 3.62161 11.0483"
                fill="#343951"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.425306 15.1528C1.34131 15.5178 2.56031 15.7988 3.52131 16.0188L3.56431 16.0288C3.83931 15.7068 4.24331 15.4978 4.69931 15.4978C4.71031 15.4978 4.72031 15.5008 4.73131 15.5008C4.78431 15.2878 4.83531 15.0488 4.88631 14.7888C3.76931 14.3998 3.01031 13.2588 3.24531 11.9788C1.78831 11.5088 1.02931 11.3978 0.0293057 11.2438C-0.0476943 12.4628 0.00830573 13.6248 0.425306 15.1528"
                fill="#343951"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.6509 17.9555C14.4009 17.9725 14.1479 17.9865 13.8999 17.9975C13.8189 18.2725 13.6819 18.5205 13.5009 18.7325C13.5989 18.8915 13.6929 19.0435 13.7859 19.1955C14.1259 18.8655 14.4509 18.5175 14.7719 18.1665C14.7249 18.1005 14.6889 18.0275 14.6509 17.9555"
                fill="#343951"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.66359 18.0776C3.37859 17.8046 3.19959 17.4226 3.19959 16.9976C3.19959 16.9886 3.20159 16.9796 3.20259 16.9726C2.35459 16.7756 1.65259 16.6106 0.821594 16.3546C1.27859 17.5226 1.90659 18.6036 2.68459 19.5606C3.02859 19.1596 3.40359 18.5356 3.66359 18.0776"
                fill="#343951"
            />
        </svg>
    );
};

export default { Tv, Connection };
