import React, { Component } from 'react';
import moment from 'moment';

import './dashboardpage.scss';
import { ConnectedServices } from '../../connectedservices/ConnectedServices';
import ProntoClient from '../../../models/ProntoClient';
import backgroundImage from '../../../assets/Illustration/Colorbox.svg';
import dashboardImage from '../../../assets/Illustration/Dashboard.svg';

const TopItem = () => {
    return (
        <div className="topItem">
            {/* <TopItemArt /> */}
            <img alt="Wavy background" src={backgroundImage} className="backgroundImage" />
            <img alt="Wavy background" src={dashboardImage} className="dashboardImage" />
            <div className="topItemContent">
                <div className="contentHeader">Welcome to the prototyper web client</div>
                <div className="contentText">
                    Here you will find all your connected devices. They send updates whenever changes are made they will be displayed below
                </div>
            </div>
        </div>
    );
};

interface DashBoardPageProps {
    //data: { pronto?: {[key: string]: ProntoClient};};
    data: {[key: string]: ProntoClient};
}

export default class DashBoardPage extends Component<DashBoardPageProps> {
    render() {
        const { data } = this.props;
        return (
            <div className="dashboardPageRoot">
                <div className="topItems">
                    <h2>DashBoard </h2>
                    <div className="stretchSpacer" />
                    <h2>{moment().format('LL')}</h2>
                </div>
                <TopItem />
                <div className="pageContent">
                    <ConnectedServices clients={data} />
                </div>
            </div>
        );
    }
}
