import React from 'react';
import './connectedservices.scss';
import { ConnectedItem } from '../connecteditem/ConnectedItem';
import ProntoClient from '../../models/ProntoClient';

interface ConnectedServicesProps {
    clients?: { [key: string]: ProntoClient};
}

export const ConnectedServices = (props: ConnectedServicesProps) => {
    const { clients } = props
    const connectedItemsComponents = []

    for (const key in clients) {
      if (clients.hasOwnProperty(key)) {
        const clientData = clients[key];
        connectedItemsComponents.push(
          <ConnectedItem key={key} name={key} color={clientData.color} status={clientData.status} components={clientData.components} />
        )
      }
    }

/*
    for (const key of Object.keys(props.clients)) {
      const clientData = props.data[key]
      connectedItemsComponents.push(<ConnectedItem key={key} name={key} color={clientData.color} status={clientData.status} components={clientData.components} />)
    }
*/
    return (
        <div className="functionalComponentRoot">
            <h2>Connected Services</h2>
            <div className="connectedItemsContainer">
                {/* <ConnectedItem title={title} value={value} /> */}
                {connectedItemsComponents}
            </div>
        </div>
    );
};
