import mqtt from 'mqtt';
import uuid from 'uuid';
class MqttService {
    constructor() {
        var clientID = uuid.v4();
        this.options = {
            clientId: clientID,
            connectTimeout: 5000,
            hostname: '37.123.130.22',
            port: 8883,
            path: '/'
        };
    }

    login(username, password, loginSuccessCallback) {
        this.options.username = username;
        this.options.password = password;
        this.client = mqtt.connect(this.options);

        this.client.on('connect', () => {
            if (!this.client.connected) {
                loginSuccessCallback(false);
                return;
            }
            loginSuccessCallback(true);
        });

        this.client.on('message', (topic, message, packet) => {
            // message is Buffer
            const data = JSON.parse(message);
            console.log('TCL: MqttService -> onMessage -> (topic, message, packet)', topic, data, packet);

            if (data) {
              const topicArray = topic.split('/').reverse();
              const topicStructuredData = topicArray.reduce((acc, cur) => ({[cur]: acc}), data);

              const mqttEvent = new CustomEvent('mqttEvent', { detail: topicStructuredData});
              window.dispatchEvent(mqttEvent);

              if (topicArray.includes('status')) {
                  const newArray = topicArray.reverse()
                  newArray.pop();
                  newArray.push("command");
                  const commandTopic = newArray.join('/');
                  const commandEvent = new CustomEvent('commandEvent', { detail: { topic: commandTopic }});
                  window.dispatchEvent(commandEvent);
              }
            }
            //   this.client.end();
        });
    }

    subscribe(topic) {
      console.log('TCL: MqttService -> subscribe -> topic', topic);
      this.client.subscribe(topic, (err, granted) => {
        if (!err) {
          console.log('TCL: MqttService -> subscribe -> granted', granted);
        } else {
          console.error(err);
        }
      })
    }

    publish(topic, message) {
        console.log('TCL: MqttService -> publish -> (topic, message)', topic, message);
        this.client.publish(topic);
    }
}

export default MqttService;
